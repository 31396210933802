import React from 'react'
import styled from "@emotion/styled"

const Cardwrap = styled.div`
img {
    padding: 0 !important;
    height: 18.5vw;
    width: 18.5vw !important;
    object-fit: cover;
    box-sizing: border-box;
    @media (max-width: 599px) {
        max-height: 43vw;
        max-width: 43vw;
        height: 43vw;
        width: 43vw !important;
      }
  }
h3 {
    padding-top: 2vh;
    padding-bottom: 2vh;
    font-size: 1rem;
    letter-spacing: .5px;
    font-weight: 400;
    transition: all 0.3s ease;
    @media (max-width: 599px) {
        padding-top: 2vh;
    }
}
a {
    color: #000;
    &:hover img {
        opacity: .66 !important;
    }
    &:hover h3 {
        color: rgba(0,0,0,0.5);
    }
}
address {
    letter-spacing: .5px;
    font-size: .75rem;
    color: rgba(0,0,0,0.5);
    padding-bottom: 2.5vh;
}
`

const Card = ({children}) => {
    return (
        <Cardwrap>
            {children}
        </Cardwrap>
    )
}

export default Card