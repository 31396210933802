import React, { useState } from "react"
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { CaretLeft, CaretRight } from "phosphor-react";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useMediaQuery } from 'react-responsive'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Card from '../components/card'
import Footer from '../components/footer'


const CarouselWrap = styled.div`
  height: 90vh;
  position: relative;
  &:hover button {
    opacity: 1;
  }
  @media (max-width: 599px) {
    height: 90svh;
  }
  a {
    border-bottom: 0;
    &:hover {
      border: 0;
    }
    &:hover h3 {
      opacity: 1;
    }
  }
  h3 {
    height: 10vh;
    line-height: 10vh;
    align-items: center;
    justify-content: center;
    font-size: .8125rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: rgba(0,0,0,0.33); 
    padding-bottom: 1vh;
    opacity: 0;
    transition: all 0.3s ease;
    @media (max-width: 599px) {
      position: absolute;
      bottom: -10svh;
      width: 100%;
      line-height: 10svh;
      height: 10svh;
    }
  }
  img {
    max-height: 80vh;
    max-width: 80vw;
    width: auto !important;
    height: auto !important;
    box-sizing: border-box !important;
    padding: 24px !important;
    background-color: white !important;
    @media (max-width: 599px) {
      max-width: 92vw;
      max-height: 80svh;
      padding: 0 !important;
      background-color: transparent !important;
    }
  }
  .carousel.carousel-slider {
      margin: 0 auto;
      @media (max-width: 599px) {
        width: 92vw !important;
      }
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
  .carousel .slider {
    height: 90vh;
    @media (max-width: 599px) {
      height: 90svh;
    }
  }
  .carousel .slide > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 599px) {
      transform: translateY(-60%);
    }
  }
  button {
    background: rgba(255,255,255,0.4);
    opacity: 0;
    border: 0;
    padding: 0;
    color: rgba(0,0,0,.2);
    position: absolute;
    z-index: 5;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        color: rgba(0,0,0,1);
        background: rgba(255,255,255,.8);
    }
    &.next {
      right: 0;
    }
}
`
const CarouselNav = styled.div`
`
const News = styled.section`
font-family: "Manrope";
min-width: 92vw;
background: #fff;
min-height: 20vh;
margin-top: 10vh;
margin-bottom: 10vh;
margin-left: -4vw;
padding: 3vh 4vw 0 4vw;
@media (max-width: 599px) {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.simplebar-scrollbar::before {
  background-color: rgba(0,0,0,0.33);
}
> h6 {
  font-weight: 800;
  margin-bottom: 2.5vh;
  font-size: .9125rem;
  color: rgba(0,0,0,0.7);
}
section {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
section > div {
  margin-right: 5vw;
  flex: 0 0 auto;
}
section > div:last-of-type {
  margin-right: 0;
}
section > div a > div {
  position: relative;
}
.gatsby-image-wrapper {
  left: 50%;
  transform: translateX(-50%);
}
img {
    padding: 0 !important;
    max-width: 15vw;
    max-height: 15vw;
}
h3 {
  line-height: 1rem;
  font-size: .8125rem;
  letter-spacing: .75px;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
h6 {
  flex: 100%;
}
a {
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease;
  &:hover h3 {
    opacity: 1;
  }
  &:hover img {
    opacity: .75 !important;
  }
  h3 {
    opacity: 0;
    transition: all 0.3s ease;
    text-align: center;
  }
}
`


const Projects = ({data}) => {
  const [currentSlide, setcurrentSlide] = useState(0)
  const next = () => setcurrentSlide(currentSlide + 1)
  const prev = () => setcurrentSlide(currentSlide - 1)
  const total =   data.projects.nodes.map(i => [i.projects.length])

  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' })

  return (
      <>
        <Layout>
          <Seo title="Hello"/>
            <CarouselWrap>
            {isDesktop ? 
            <>
            <CarouselNav>
              <button onClick={prev} className="prev">
                <CaretLeft size={48} weight="thin"/>
              </button>
              <button onClick={next} className="next">
                <CaretRight size={48} weight="thin"/>
              </button>
            </CarouselNav>
            {data.projects.nodes.map(node => (
            <Carousel animationHandler="fade" width={'80vw'} transitionTime={500} showArrows={false} showThumbs={false} showStatus={false} showIndicators={false} autoPlay={false} infiniteLoop={true} swipeable={false} selectedItem={currentSlide <= total ? currentSlide : setcurrentSlide(0)} onChange={(index) => setcurrentSlide(index)}
            >
                {node.projects.map(project => (
                  <div key={project.id}>{project.homepageImage && <div><Link to={`/project/${project.slug}`}><GatsbyImage image={project.homepageImage.gatsbyImageData} alt="image" objectFit="contain" /><h3>{project.projectTitle}</h3></Link></div>}{ !project.homepageImage && <div><Link to={`/project/${project.slug}`}><GatsbyImage image={project.bannerImage.gatsbyImageData} alt="image" objectFit="contain" /><h3>{project.projectTitle}</h3></Link></div> }</div>
                ))}
            </Carousel>
            ))}
            </> : 
            <>
            {data.projects.nodes.map(node => (
            <Carousel width={'80vw'} showArrows={false} showThumbs={false} showStatus={false} showIndicators={false} autoPlay={false} infiniteLoop={true} swipeable={true} emulateTouch={true} selectedItem={currentSlide <= total ? currentSlide : setcurrentSlide(0)} onChange={(index) => setcurrentSlide(index)}
            >
              {node.projects.map(project => (
                  <div key={project.id}>{project.homepageImage && <div><Link to={`/project/${project.slug}`}><GatsbyImage image={project.homepageImage.gatsbyImageData} alt="image" objectFit="contain" /><h3>{project.projectTitle}</h3></Link></div>}{ !project.homepageImage && <div><Link to={`/project/${project.slug}`}><GatsbyImage image={project.bannerImage.gatsbyImageData} alt="image" objectFit="contain" /><h3>{project.projectTitle}</h3></Link></div> }</div>
              ))}
            </Carousel>
            ))}
            </>
            }
            </CarouselWrap>
            <News>
            <h6>Latest news</h6>
            <SimpleBar style={{ maxWidth: "92vw"}}>
            <section>
            {data.news.nodes.map(node => (
                    <Card key={node.id}><Link to={`/news/${node.slug}`} asModal><div><GatsbyImage image={node.image.gatsbyImageData} alt="image" objectFit="cover" /></div></Link></Card>
                ))}
            </section>
            </SimpleBar>
          </News>
        </Layout>
          <Footer />
      </>
    )
}

export default Projects

export const query = graphql`
{
  projects: allContentfulHomepage {
    nodes {
      projects {
        projectTitle
        slug
        id
        bannerImage {
          gatsbyImageData
        }
        homepageImage {
          gatsbyImageData
        }
      }
    }
  }
  news: allContentfulNews(sort: {fields: date, order: DESC}) {
    nodes {
      id
      slug
      title
      image {
        gatsbyImageData
      }
      date
    }
  }
}
`
